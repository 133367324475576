import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import configData from "../../config.json";

function Doctype(props) {
  useEffect(() => {
    getDataOption();
  }, []);

  const [dataOption1, setDataOption1] = useState([]);
  const [dataOption2, setDataOption2] = useState([]);
  const [dataOption3, setDataOption3] = useState([]);

  const getDataOption = () => {
    axios
      .get(configData.API_URL + "/w3_api/api/category/read.php")
      .then((Response) => {
        setDataOption1(Response.data.level);
        setDataOption2(Response.data.type);
        setDataOption3(Response.data.topic);
      });
  };
  const optionState1 = [];
  const optionState2 = [];
  const optionState3 = [];

  optionState1[props.sid1] = true;
  optionState2[props.sid2] = true;
  optionState3[props.sid3] = true;

  const listItems1 = dataOption1.map((val) => {
    return (
      <option value={val.cat_id} key={props.key} selected={optionState1[val.cat_id]}>
        {val.cat_name}
      </option>
    );
  });
  const listItems2 = dataOption2.map((val) => {
    return (
      <option value={val.cat_id} key={props.key} selected={optionState2[val.cat_id]}>
        {val.cat_name}
      </option>
    );
  });
  const listItems3 = dataOption3.map((val) => {
    return (
      <option value={val.cat_id} key={props.key} selected={optionState3[val.cat_id]}>
        {val.cat_name}
      </option>
    );
  });

  return (
    <Row>
      <Col lg={4}>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>ระดับหน่วยงาน</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="ระดับหน่วยงาน"
            as="select"
            onChange={(event) => {
              props.setLevel(event.target.value);
            }}
          >
            <option></option>
            {listItems1}
          </FormControl>
        </InputGroup>
      </Col>

      <Col lg={3} md={3}>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>ประเภทเอกสาร</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="ประเภทเอกสาร"
            as="select"
            onChange={(event) => {
              props.setType(event.target.value);
            }}
          >
            <option></option>
            {listItems2}
          </FormControl>
        </InputGroup>
      </Col>
      <Col>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>หัวข้อเรื่อง</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl aria-label="หัวข้อเรื่อง" as="select"
                      onChange={(event) => {
                        props.setTopic(event.target.value);
                      }}
          >
            <option></option>
            {listItems3}
          </FormControl>
        </InputGroup>
      </Col>
    </Row>
  );
}

export default Doctype;
