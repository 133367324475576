import React, { useState, useEffect , useContext} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  InputGroup,
  FormControl,
} from "react-bootstrap";
import {
  Container,
  Button,
  Input,
  Row,
  Col,
  Modal,
} from "reactstrap";
import Paper from "@material-ui/core/Paper";
import {
  TreeDataState,
  SortingState,
  CustomTreeData,
  PagingState,
  IntegratedPaging,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableTreeColumn,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import axios from "axios";
import configData from "../../config.json";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AuthContext } from "auth/auth.js";
import DocType from "components/DocTypeDropdown/DocType";
import DatePicker from "react-datepicker";

const getChildRows = (row, rootRows) => (row ? row.items : rootRows);



function TreeTable(props) {

const [data, setData] = useState([]);
const [detail, setDetail] = useState("");
const [caption, setCaption] = useState("");
const [file, setFile] = useState("");
const [thumbnail, setThumbnail] = useState("");
const [searchText, setSearchText] = useState("");
const [pageSizes] = useState([5, 10, 20, 50, 100]);
const { currentUser } = useContext(AuthContext);
const [showEditModal, setShowEditModal] = useState(false);
const [iD, setID] = useState("");
const [firstPage, setFirstPage] = useState(0);
const [docYear, setDocYear] = useState(0);
const [thumbNail, setThumbNail] = useState("");
const [files, setFiles] = useState("");
const [level, setLevel] = useState(0);
const [type, setType] = useState(0);
const [topic, setTopic] = useState(0);
const [startD, setStartD] = useState(new Date());
const [endD, setEndD] = useState(new Date());
const [date, setDate] = useState(new Date());
const today = new Date();
const today_string = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

const daTa = {
  content_id: iD,
  ct_caption: caption,
  ct_detail: detail,
  ct_firstpage: firstPage,
  ct_thumbnail: thumbNail,
  ct_file: files,
  ct_level: level,
  ct_type: type,
  ct_topic: topic,
  ct_doc_year: docYear,
  ct_start:
    startD.getFullYear() +
    "-" +
    (startD.getMonth() + 1) +
    "-" +
    startD.getDate(),
  ct_end:
    endD.getFullYear() + "-" + (endD.getMonth() + 1) + "-" + endD.getDate(),
  ct_date:
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate(),
};
const firstPageSel = [];
firstPageSel[firstPage] = true;



  useEffect(() => {
    getData("");
  }, []);

  const toggleModal = () => {
    setModal(!modal);
        settxtToclipboard(false);
  };

      const toggleModal2 = () => {
        setModal2(!modal2);
      };
      const toggleConFModal = () => {
        setConFModal(!conFModal);
      };
      
  const showModal = (row) => {
    setID(row["root_code"]);
    setQrValue(configData.WEB_BASE + "?i=" + row["root_code"]);
    let remText = row["d_download"].replace(/ /g, "");
    let length = remText.length;
    if (length > 0) {
      setCaption(row["root_name"]);
      setDetail(row["d_detail"]);
      setFile(row["d_download"]);
      setThumbnail(row["thumbnail"]);
      toggleModal();
    }
  };

  const [modal, setModal] = useState(false);
  const [conFModal, setConFModal] = useState(false);

  const [modal2, setModal2] = useState(false);
  const [qrValue, setQrValue] = useState("");
  const [txtToclipboard, settxtToclipboard] = useState(false);

  const [conFMtitle, setconFMtitle] = useState("");
  const [conFMcaption, setconFMcaption] = useState("");


    const downloadQRCode = () => {
      // Generate download with use canvas and stream
      const canvas = document.getElementById("qr-gen-download");
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${caption}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      toggleModal2();
    };

  const ConFirmBox = (title,caption) => {
    setconFMtitle(title);
    setconFMcaption(caption);
    toggleConFModal();
  };


  const getData = (keyword) => {
    axios
      .get(configData.API_URL + "/w3_api/api/post/newstableread.php", {
        params: {
          keyword: keyword,
        },
      })
      .then((Response) => setData(Response.data));
  };
  // กำหนด Column ของข้อมูลที่ต้องการแสดงในตาราง
  const [columns] = useState([
    { name: "root_name", title: "รายการ" },
    // { name: 'd_download', title: 'DL' },
    // { name: "d_docyear", title: "ปีเอกสาร" },
    { name: "d_date", title: "วันที่บันทึก" },
  ]);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      
      onClick={() => showModal(row)}
      style={{
        cursor: "pointer",
        backgroundColor:
          datediff(row["d_date"], today_string) <= 7 ? "#b5ffe1" : "#FFFFFF",
      }}
    />
  );
  const [tableColumnExtensions] = useState([
    { columnName: "root_name", width: 950 },
  ]);


// edit Data Zone
  const updateData = () => {
    axios
      .get(configData.API_URL + "/w3_api/api/post/update.php", { params: daTa })
      .then((Response) => {
        getData("");
        alert("บันทึกข้อมูลแล้ว");
      })
      .catch((err) => {
        alert(err);
      });
    setShowEditModal(false);
    // console.log(update);
  };

const getDataToEdit = (id) => {

  axios
    .get(configData.API_URL + "/w3_api/api/post/read_single.php", {
      params: {
        id: id,
      },
    })
    .then((Response) => {
      setID(Response.data.content_id);
      setCaption(Response.data.ct_caption);
      setFirstPage(Response.data.ct_firstpage);
      setThumbNail(Response.data.ct_thumbnail);
      setDetail(Response.data.ct_detail);
      setFiles(Response.data.ct_file);
      setLevel(Response.data.ct_level);
      setType(Response.data.ct_type);
      setTopic(Response.data.ct_topic);
      // setDocYear(Response.data.ct_doc_year);
      Response.data.ct_doc_year === "0"
        ? setDocYear(-543)
        : setDocYear(Number(Response.data.ct_doc_year));
      setStartD(new Date(Response.data.ct_start));
      setEndD(new Date(Response.data.ct_end));
      setDate(new Date(Response.data.ct_date));
    });

  setShowEditModal(true);
};

  const deleteDataSubmit = (id,r) => {
      if(r){
        axios
          .get(configData.API_URL + "/w3_api/api/post/delete.php", {
            params: { content_id: id },
          })
          .then((Response) => {
            getData("");
            alert("ลบข้อมูลแล้ว");
          })
          .catch((err) => {
            alert(
              "ERROR เกิดปัญหาบางอย่าง Delete ข้อมูลไม่ได้กรุณาติดต่อผู้ดูแลระบบ"
            );
          });
        // console.log(update);
        setShowEditModal(false);
        setModal2(false);
        setModal(false);
        toggleConFModal();
      }
 };
 function datediff(first, second) {
   return Math.round((new Date(second) - new Date(first)) / (1000 * 60 * 60 * 24));
 }
  return (
    <>
      <Container>
        <Row className="align-items-center mb-3">
          <Col md="3"></Col>
          <Col md="6" className="mr-0">
            {data.length === 0 ? (
              <>
                <h4 className="title text-danger">
                  กำลังโหลดข้อมูลกรุณารอสักครู่
                </h4>
              </>
            ) : null}
            <Input
              className="form-control-success"
              placeholder="ค้นหาเอกสารได้ที่นี่ ->เช่น พิมพ์ 'แผนกลยุทธ์' และกดปุ่ม 'ค้นหา' เพื่อค้นหาแผนกลยุทธ์"
              type="text"
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
            />
          </Col>
          <Col md="auto" className="ml-0">
            <Button
              className="btn-link ml-1"
              color="default"
              onClick={() => getData(searchText)}
              outline
            >
              <i className="fa fa-search" /> ค้นหา
            </Button>
          </Col>
          <Col md="auto"></Col>
        </Row>
      </Container>
      <Paper>
        <Grid rows={data} columns={columns}>
          <TreeDataState />
          <SortingState />
          <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[1]} />
          <CustomTreeData getChildRows={getChildRows} />
          <IntegratedPaging />
          <IntegratedSorting />
          <Table
            rowComponent={TableRow}
            columnExtensions={tableColumnExtensions}
          />
          <TableHeaderRow showSortingControls />
          <TableTreeColumn for="root_name" />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
        <h6>
          หมายเหตุ : รายการที่มี
          <span style={{ background: "#b5ffe1" }}>แถบสีเขียว</span>
          คือรายการที่เผยแพร่ล่าสุดไม่เกิน 7 วันนับจากวันที่เผยแพร่
        </h6>

        {/* Modal */}
        <Modal
          isOpen={modal2}
          toggle={toggleModal2}
          className="modal-dialog modal-md"
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={toggleModal2}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <h5 className="modal-title text-left" id="exampleModalLabel">
              คลิกที่ QRCode เพื่อดาวน์โหลด
            </h5>
          </div>
          <div className="modal-body text-center">
            <QRCode
              id="qr-gen-download"
              value={qrValue}
              size={250}
              level={"H"}
              includeMargin={true}
              className="mr-3 mt-2"
              onClick={downloadQRCode}
            />
          </div>
        </Modal>

        <Modal
          isOpen={modal}
          toggle={toggleModal}
          className="modal-dialog modal-lg"
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={toggleModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <h5 className="modal-title text-left" id="exampleModalLabel">
              {caption}
            </h5>
            <div className="text-right">
              {currentUser != null ? (
                currentUser.loginstatus ? (
                  <>
                    <Button
                      className="btn-link"
                      color="info"
                      type="button"
                      onClick={() => {
                        getDataToEdit(iD);
                        console.log("test success");
                      }}
                    >
                      <i className="nc-icon nc-ruler-pencil" />
                    </Button>
                  </>
                ) : null
              ) : null}
            </div>
          </div>
          <div className="modal-body text-left">
            <img
              alt=""
              className="img-rounded img-responsive"
              src={thumbnail}
            />
            <div
              className="mt-4 text-left"
              dangerouslySetInnerHTML={{ __html: detail }}
            ></div>
          </div>
          <div className="modal-footer">
            <div className="modal-body text-left">
              <div>
                <QRCode
                  className="mr-2"
                  id="qr-gen"
                  value={qrValue}
                  size={99}
                  level={"H"}
                  includeMargin={true}
                  onClick={toggleModal2}
                />
              </div>
              <div>
                <CopyToClipboard
                  text={qrValue}
                  onCopy={() => settxtToclipboard(true)}
                  className="mt-0"
                >
                  <span>{qrValue}</span>
                </CopyToClipboard>
                {txtToclipboard ? (
                  <span style={{ color: "red" }}> Copied.</span>
                ) : null}
              </div>
            </div>
            <div>
              <Button
                className="btn-link"
                color="info"
                type="button"
                href={file}
                target="_blank"
              >
                <h4>
                  <i className="nc-icon nc-cloud-download-93" />
                  <span className="ml-3">เปิดเอกสาร</span>
                </h4>
              </Button>
            </div>
          </div>
        </Modal>

        {/*! Edit Modal */}
        <Modal
          isOpen={showEditModal}
          toggle={toggleModal2}
          className="modal-dialog modal-lg"
        >
          <div className="modal-header">
            <h4>แก้ไขเนื้อหาบนเว็บไซต์</h4>
          </div>
          <div className="modal-body">
            <div>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">หัวข้อ</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="หัวข้อ"
                  aria-label="caption"
                  aria-describedby="basic-addon1"
                  value={caption}
                  onChange={(event) => {
                    setCaption(event.target.value);
                  }}
                />
              </InputGroup>
              <div className="mb-3">
                <label>รายละเอียด</label>
                <ReactQuill theme="snow" value={detail} onChange={setDetail} />
              </div>

              <Row>
                <Col lg={3}>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>ปีเอกสาร</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="ปีเอกสาร"
                      value={docYear + 543}
                      onChange={(event) => {
                        setDocYear(event.target.value - 543);
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col lg={3}>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>หน้าหลัก</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="ประเภทเอกสาร"
                      as="select"
                      onChange={(event) => {
                        setFirstPage(event.target.value);
                      }}
                    >
                      <option value="1" selected={firstPageSel[1]}>
                        yes
                      </option>
                      <option value="0" selected={firstPageSel[0]}>
                        no
                      </option>
                    </FormControl>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon3">
                        ภาพหน้าปก (thumbnail)
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      id="thumbnail-url"
                      aria-describedby="basic-addon3"
                      value={thumbNail}
                      onChange={(event) => {
                        setThumbNail(event.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>

              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>ไฟล์เอกสาร</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-label="ไฟล์แนบ"
                  value={files}
                  onChange={(event) => {
                    setFiles(event.target.value);
                  }}
                />
              </InputGroup>

              <DocType
                sid1={level}
                sid2={type}
                sid3={topic}
                setLevel={setLevel}
                setType={setType}
                setTopic={setTopic}
              />

              <Row>
                <Col>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>วันที่เริ่ม</InputGroup.Text>
                    </InputGroup.Prepend>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={startD}
                      onChange={(date) => setStartD(date)}
                      selectsStart
                      startDate={startD}
                      endDate={endD}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>วันที่เลิก</InputGroup.Text>
                    </InputGroup.Prepend>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={endD}
                      onChange={(date) => setEndD(date)}
                      selectsEnd
                      startDate={startD}
                      endDate={endD}
                      minDate={startD}
                    />
                  </InputGroup>
                </Col>

                <Col>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>วันที่บันทึก</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl readOnly value={date} />
                  </InputGroup>
                </Col>
              </Row>
            </div>
          </div>

          <div className="modal-footer">
            <Button
              className="btn-simple text-success"
              type="button"
              variant="success"
              onClick={() => {
                updateData();
              }}
            >
              บันทึก
            </Button>
            <Button
              className="mr-5 btn-simple text-danger"
              type="button"
              variant="danger"
              onClick={() =>
                ConFirmBox(
                  "กองแผนงาน มหาวิทยาลัยศิลปากร",
                  "ต้องการลบข้อมูลนี้ ?"
                )
              }
            >
              ลบ
            </Button>
            <Button
              className="btn-simple text-warning ml-5"
              type="button"
              variant="danger"
              onClick={() => setShowEditModal(false)}
            >
              ยกเลิก
            </Button>
          </div>
        </Modal>
        {/* End Modal */}

        <Modal
          isOpen={conFModal}
          toggle={toggleConFModal}
          className="modal-dialog modal-md"
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={toggleConFModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
            {conFMtitle}
          </div>
          <div className="modal-body text-center">{conFMcaption}</div>
          <div className="modal-footer">
            <Button
              className="btn-simple text-danger"
              type="button"
              variant="danger"
              onClick={() => deleteDataSubmit(iD, true)}
            >
              ลบ
            </Button>
            <Button
              className="btn-simple text-warning"
              type="button"
              variant="danger"
              onClick={() => toggleConFModal()}
            >
              ยกเลิก
            </Button>
          </div>
        </Modal>
      </Paper>
    </>
  );
}

export default TreeTable;
