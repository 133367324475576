/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import configData from "../../config.json";
// import thumb_img from "../../assets/img/link_thmb.jpg";
import Grid from "@material-ui/core/Grid";
// reactstrap components
import { Row} from "reactstrap";

// core components
const removeHTML = (str) => {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 3,
    flexGrow: 1,
    height: 200
  },
  media : {
    width: "100%",
    height: 140
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

function SectionBanner() {
  useEffect(() => {
    getData("ct_date", "DESC");
  }, []);

  // const [thumbBackground] = useState(thumb_img);
  const [item, setItems] = useState([]);

  const getData = () => {
    axios
      .get(configData.API_URL + "/w3_api/api/post/showbn.php", {
        params: {
          field: "ct_date",
          desc: "desc",
          type: "20",
        },
      })
      .then((Response) => setItems(Response.data));
  };

  var items = [];
  if (item.length) {
    items = item;
  }
  const classes = useStyles();

  // console.log(item);
  return (
    <>
      <div className="section section-su section-gold">
        <Row className="justify-content-center">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {items.map((item) => {
              return (
                <Grid item md={3} sm={6} xs={12}>
                  <Card className={classes.root}>
                    <Button
                      size="small"
                      color="primary"
                      href={item.ct_file}
                      target="_blank"
                    >
                      <CardActionArea>
                        {/* <CardMedia
                      component="img"
                      className={classes.media}
                      src={thumbBackground}
                      image={item.ct_thumbnail}
                    /> */}
                        <CardContent height="140">
                          <Typography gutterBottom variant="h5" component="h2">
                            {item.ct_caption}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {removeHTML(item.ct_detail)}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        {/* <Button
                  size="small"
                  color="primary"
                  href={item.ct_file}
                  target="_blank"
                >
                  Open Link
                </Button> */}
                      </CardActions>
                    </Button>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Row>
      </div>
    </>
  );
}

export default SectionBanner;
