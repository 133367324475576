/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState,useEffect} from "react";
import { Pie } from "react-chartjs-2";
import axios from "axios";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import configData from "../../config.json";

// core components

function SectionRating() {

  const [pln, setPln] = useState([]);
  const [cio, setCio] = useState([]);
  const [web, setWeb] = useState([]);
  const [cpp, setCpp] = useState([]);
  
   useEffect(() => {

    axios
       .get(configData.API_URL + "/rating/result.php", {
         params: {
           s_id: "cio",
         },
       })
       .then((Response) => setCio(Response.data));
    axios
         .get(configData.API_URL + "/rating/result.php", {
           params: {
             s_id: "web",
           },
         })
         .then((Response) => setWeb(Response.data));
    axios
           .get(configData.API_URL + "/rating/result.php", {
             params: {
               s_id: "cpp",
             },
           })
           .then((Response) => setCpp(Response.data));
    axios
      .get(configData.API_URL + "/rating/result.php", {
        params: {
          s_id: "plan",
        },
      })
      .then((Response) => setPln(Response.data));
   }, []);

      let optionsCIO = {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
            display: false,
          },
          title: {
            display: true,
            position: "bottom",
            text: `คะแนนความพึงพอใจ ${
              cio.status === 1 ? cio.rating.avgscore : 0
            } จาก ${cio.status === 1 ? cio.rating.all_c : 0} ท่าน`,
          },
        },
      };

      let dataCIO = {
        labels: [`5 คะแนน`, `4 คะแนน`, `3 คะแนน`, `2 คะแนน`, `1 คะแนน`],
        datasets: [
          {
            data: [
              cio.status === 1 ? cio.rating.c5 : 0,
              cio.status === 1 ? cio.rating.c4 : 0,
              cio.status === 1 ? cio.rating.c3 : 0,
              cio.status === 1 ? cio.rating.c2 : 0,
              cio.status === 1 ? cio.rating.c1 : 0,
            ],
            backgroundColor: [
              "rgb(0, 204,102,1)",
              "rgb(102,255, 178,1)",
              "rgb(229, 255,0,1)",
              "rgb(231, 169,0,1)",
              "rgb(255, 23,29,1)",
            ],
            borderWidth: 0,
          },
        ],
      };

   let optionsPln = {
     responsive: true,
     plugins: {
       legend: {
         position: "bottom",
         display: false,
       },
       title: {
         display: true,
         position: "bottom",
         text: `คะแนนความพึงพอใจ ${
           pln.status === 1 ? pln.rating.avgscore : 0
         } จาก ${pln.status === 1 ? pln.rating.all_c : 0} ท่าน`,
       },
     },
   };

   let dataPln = {
     labels: [`5 คะแนน`, `4 คะแนน`, `3 คะแนน`, `2 คะแนน`, `1 คะแนน`],
     datasets: [
       {
         data: [
           pln.status === 1 ? pln.rating.c5 : 0,
           pln.status === 1 ? pln.rating.c4 : 0,
           pln.status === 1 ? pln.rating.c3 : 0,
           pln.status === 1 ? pln.rating.c2 : 0,
           pln.status === 1 ? pln.rating.c1 : 0,
         ],
         backgroundColor: [
           "rgb(0, 204,102,1)",
           "rgb(102,255, 178,1)",
           "rgb(229, 255,0,1)",
           "rgb(231, 169,0,1)",
           "rgb(255, 23,29,1)",
         ],
         borderWidth: 0,
       },
     ],
   };

   let optionsWeb = {
     responsive: true,
     plugins: {
       legend: {
         position: "bottom",
         display: false,
       },
       title: {
         display: true,
         position: "bottom",
         text: `คะแนนความพึงพอใจ ${
           web.status === 1 ? web.rating.avgscore : 0
         } จาก ${web.status === 1 ? web.rating.all_c : 0} ท่าน`,
       },
     },
   };

   let dataWeb = {
     labels: [`5 คะแนน`, `4 คะแนน`, `3 คะแนน`, `2 คะแนน`, `1 คะแนน`],
     datasets: [
       {
         data: [
           web.status === 1 ? web.rating.c5 : 0,
           web.status === 1 ? web.rating.c4 : 0,
           web.status === 1 ? web.rating.c3 : 0,
           web.status === 1 ? web.rating.c2 : 0,
           web.status === 1 ? web.rating.c1 : 0,
         ],
         backgroundColor: [
           "rgb(0, 204,102,1)",
           "rgb(102,255, 178,1)",
           "rgb(229, 255,0,1)",
           "rgb(231, 169,0,1)",
           "rgb(255, 23,29,1)",
         ],
         borderWidth: 0,
       },
     ],
   };

   
   let optionsCpp = {
     responsive: true,
     plugins: {
       legend: {
         position: "bottom",
         display: false,
       },
       title: {
         display: true,
         position: "bottom",
         text: `คะแนนความพึงพอใจ ${
           cpp.status === 1 ? cpp.rating.avgscore : 0
         } จาก ${cpp.status === 1 ? cpp.rating.all_c : 0} ท่าน`,
       },
     },
   };

   let dataCpp = {
     labels: [`5 คะแนน`, `4 คะแนน`, `3 คะแนน`, `2 คะแนน`, `1 คะแนน`],
     datasets: [
       {
         data: [
           cpp.status === 1 ? cpp.rating.c5 : 0,
           cpp.status === 1 ? cpp.rating.c4 : 0,
           cpp.status === 1 ? cpp.rating.c3 : 0,
           cpp.status === 1 ? cpp.rating.c2 : 0,
           cpp.status === 1 ? cpp.rating.c1 : 0,
         ],
         backgroundColor: [
           "rgb(0, 204,102,1)",
           "rgb(102,255, 178,1)",
           "rgb(229, 255,0,1)",
           "rgb(231, 169,0,1)",
           "rgb(255, 23,29,1)",
         ],
         borderWidth: 0,
       },
     ],
   };


  return (
    <>
      <div className="section section-gray">
        <Container>
          <div className="title">
            <h3>การประเมินความพึงพอใจ</h3>
            <p>
              โปรดตอบแบบประเมินเพื่อเราจะได้พัฒนาการให้บริการให้ดีขึ้น
              คลิกที่ชื่อของแผนผังเพื่อดูผลการประเมิน
              คลิกที่ปุ่มด้านล่างเพื่อทำแบบประเมิน{" "}
              <span className="text-danger">
                ขอขอบคุณทุกท่านที่ใช้บริการและทุกท่านที่ทำแบบประเมินให้กองแผนงาน เราจะนำผลประเมินและคำติชมไปพัฒนาให้ดีขึ้น
              </span>
            </p>
            <br />
          </div>
          <Row className="text-center">
            <Col md="3">
              <a
                href="https://www.plan.su.ac.th/rating/index.php?s_id=plan&s_name=กองแผนงาน (ในภาพรวม)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <label className="label label-success mr-1">กองแผนงาน</label>
              </a>
              <Pie options={optionsPln} data={dataPln} />
              <a
                href="https://www.plan.su.ac.th/rating/input.php?s_id=plan&s_name=กองแผนงาน (ในภาพรวม)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="btn-round btn-sm" color="success">
                  ประเมินความพึงพอใจการให้บริการของกองแผนงาน (ในภาพรวม)
                </Button>
              </a>
            </Col>
            <Col md="3">
              <a
                href="https://www.plan.su.ac.th/rating/index.php?s_id=web&s_name=เว็บไซต์กองแผนงาน"
                target="_blank"
                rel="noopener noreferrer"
              >
                <label className="label label-primary mr-1">
                  เว็บไซต์กองแผนงาน
                </label>
              </a>
              <Pie options={optionsWeb} data={dataWeb} />
              <a
                href="https://www.plan.su.ac.th/rating/input.php?s_id=web&s_name=เว็บไซต์กองแผนงาน"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="btn-round btn-sm" color="primary">
                  ประเมินความพึงพอใจเว็บไซต์กองแผนงาน
                </Button>
              </a>
            </Col>
            <Col md="3">
              <a
                href="https://www.plan.su.ac.th/rating/index.php?s_id=cio&s_name=เว็บไซต์กองแผนงาน"
                target="_blank"
                rel="noopener noreferrer"
              >
                <label className="label label-primary mr-1">ระบบ CIO</label>
              </a>
              <Pie options={optionsCIO} data={dataCIO} />
              <a
                href="https://www.plan.su.ac.th/rating/input.php?s_id=cio&s_name=เว็บไซต์กองแผนงาน"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="btn-round btn-sm" color="primary">
                  ประเมินความพึงพอใจระบบ CIO
                </Button>
              </a>
            </Col>
            <Col md="3">
              <a
                href="https://www.plan.su.ac.th/rating/index.php?s_id=cpp&s_name=เว็บไซต์กองแผนงาน"
                target="_blank"
                rel="noopener noreferrer"
              >
                <label className="label label-primary mr-1">
                  ระบบเปลี่ยนแปลงโครงการตามภารกิจเชิงนโยบายฯ
                </label>
              </a>
              <Pie options={optionsCpp} data={dataCpp} />
              <a
                href="https://www.plan.su.ac.th/rating/input.php?s_id=cpp&s_name=เว็บไซต์กองแผนงาน"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="btn-round btn-sm" color="primary">
                  ประเมินความพึงพอใจระบบเปลี่ยนแปลงฯ
                </Button>
              </a>
            </Col>
          </Row>
          <br />
          {/* <Row>
            <Col md="6">
              <div className="title">
                <h3>Labels</h3>
              </div>
              <label className="label label-default mr-1">Default</label>
              <label className="label label-primary mr-1">Primary</label>
              <label className="label label-info mr-1">Info</label>
              <label className="label label-success mr-1">Success</label>
              <label className="label label-warning mr-1">Warning</label>
              <label className="label label-danger">Danger</label>
            </Col>
          </Row> */}
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionRating;
