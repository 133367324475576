import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import configData from "../../config.json";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function DialogSelect(props) {
  useEffect(() => {
    getData();
  }, []);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [opt1, setOpt1] = useState(0);
  const [opt2, setOpt2] = useState(0);
  const [opt3, setOpt3] = useState(4);

  // const optionSelectDaTa = {
  //   f1: opt1,
  //   f2: opt2,
  //   year: opt3,
  // };

  const [optionChoice1] = useState([
    { name: "ระดับหน่วยงาน", sr: "1" },
    { name: "ประเภทเอกสาร", sr: "2" },
    { name: "หัวข้อเรื่อง", sr: "3" },
  ]);

  const [optionChoice2, setOptionChoice2] = useState([]);

  const [optionChoice3, setOptionChoice3] = useState([]);

  const getData = () => {
    axios
      .get(configData.API_URL + "/w3_api/api/post/readyearhasdata.php")
      .then((Response) => setOptionChoice3(Response.data));
  };

  const handleChange1 = (event) => {
    var tmpArray = [];
    setOpt1(Number(event.target.value) || "");

    optionChoice1.map((val, key) =>
      event.target.value === val.sr
        ? tmpArray.push({})
        : tmpArray.push({ name: val.name, sr: val.sr })
    );
    setOptionChoice2(tmpArray);
  };

  const handleChange2 = (event) => {
    setOpt2(Number(event.target.value) || "");
    setOpt3(new Date().getFullYear());
  };

  const handleChange3 = (event) => {
    setOpt3(Number(event.target.value) || "");
    console.log(opt3);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        คลิกที่นี่เพื่อเปิดตัวเลือกในการจัดเรียงข้อมูล
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>เลือกหัวข้อและปีเพื่อจัดเรียงข้อมูล</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="demo-dialog-native">ลำดับที่ 1</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={opt1}
                onChange={handleChange1}
                input={<Input />}
              >
                {optionChoice1.map((val, key) => {
                  return <MenuItem value={val.sr}>{val.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-dialog-select-label">ลำดับที่ 2</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={opt2}
                onChange={handleChange2}
                input={<Input />}
              >
                {optionChoice2.map((val, key) => {
                  return <MenuItem value={val.sr}>{val.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-dialog-select-label">ปีของข้อมูล</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={opt3}
                onChange={handleChange3}
                input={<Input />}
              >
                <MenuItem value={4}>{""}</MenuItem>
                <MenuItem value={3}>{"ทั้งหมด"}</MenuItem>
                {optionChoice3.map((val, key) => {
                  var year4choice = val.ct_doc_year;
                  var year4label =
                    val.ct_doc_year === "0"
                      ? "ไม่ระบุ"
                      : Number(val.ct_doc_year) + 543;
                  return (
                    <MenuItem value={year4choice}>
                      {year4label}({val.am})
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.getData(opt1, opt2, opt3,"");
              handleClose();
            }}
            color="primary"
          >
            Ok
          </Button>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogSelect;
