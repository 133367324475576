/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
// import { Container, Row, Col } from "reactstrap";

// core components

function SectionBB() {
  return (
    <div className="App">
      <iframe
        width="100%"
        height="800"
        src="https://www.bb.go.th/embed"
        frameborder="0"
        title="https://www.bb.go.th"
      ></iframe>
      <p className="text-center">
        เครดิตปฏิทินงบประมาณรายจ่ายประจำปี : เว็บไซต์สำนักงบประมาณ
        https://www.bb.go.th
      </p>
    </div>
  );
}

export default SectionBB;
