import Card from "@material-ui/core/Card";
// import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
      margin: 3,
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      justifyContent: 'center',
      marginLeft: "auto",
      marginRight: "auto",
    },
  }));

function OrgCard(props) {
    const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea>
        {/* <CardMedia
          component="img"
          height="99"
          image={props.image}
          title={props.title}
          alt={props.title}
        /> */}
        <CardContent>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className="text-center"
          >
            {props.detail}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
} 
export default OrgCard;