/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// import configData from "../../config.json";
// import axios from "axios";

// reactstrap components
import {
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";
import emailjs from "@emailjs/browser";

// core components

function SectionLogin() {
  const [modal, setModal] = useState(false);
  // const [emailState, setEmailState] = useState("");
  const [sname, setSname] = useState("");
  const [semail, setSemail] = useState("");
  const [smessage, setSmessage] = useState("");
  // const [sndStatus, setSndStatus] = useState("");

  const sndEmail = (sname, semail, smessage) => {
    // e.preventDefault();

    var templateParams = {
      snd_email: semail,
      snd_name: sname,
      message: smessage,
      reply_to: "plansu.web@gmail.com",
    };

    emailjs
      .send(
        "service_jyg7a94",
        "template_k0pailq",
        templateParams,
        "BLpBrRI39se1Mo9XJ"
      )
      .then(
        function (response) {
          alert("ส่งข้อความแล้ว");
        },
        function (error) {
        alert(`FAILED...  ${error}`);
        }
      );
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  // const sndEmail = (sname, semail, smessage) => {
  //   axios
  //     .get("https://cio.su-plan.org/gmail/email_www.php", {
  //       params: {
  //         to: "kupulsub_s@su.ac.th",
  //         subject: "ข้อความจากระบบสายตรงผู้อำนวยการกองแผนงาน",
  //         message:
  //           "ได้รับข้อความจาก " +
  //           sname +
  //           " " +
  //           semail +
  //           " ข้อความว่า : " +
  //           smessage,
  //       },
  //     })
  //     .then((Response) => {
  //       setEmailState(Response.data);
  //       setSname("");
  //       setSemail("");
  //       setSmessage("");

  //       if (emailState.result) {
  //         setSndStatus("ข้อความของคุณถูกส่งแล้ว");
  //       } else {
  //         setSndStatus(
  //           "ไม่มีการส่งข้อความ เนื่องจากไม่มีการพิมพ์ข้อความในช่องข้อความ"
  //         );
  //       }

  //       toggleModal();
  //     })
  //     .catch((e)=>{
  //                 setSndStatus(
  //                   `ไม่มีการส่งข้อความ เนื่องจากระบบขัดข้อง : ${e.message}`
  //                 );
  //     });
  // };
  // console.log(smessage);
  return (
    <>
      <div className="section section-su landing-section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto mx-auto my-auto" md="2">
              <img
                alt="..."
                className="img-rounded img-responsive"
                src="https://www.su.ac.th/th/images-academic/directors/11.png"
              />
              <h6 className="mt-3 text-center">นางสาวเสาวณีย์ คูพูลทรัพย์</h6>
              <h6 className="mt-0 text-center">ผู้อำนวยการกองแผนงาน</h6>
            </Col>
            <Col className="ml-auto mr-auto" md="7">
              <h2 className="text-center">สายตรงผู้อำนวยการกองแผนงาน</h2>
              <p className="text-center">
                ในกรณีที่ไม่ต้องการการติดต่อกลับหรือไม่ต้องการระบุตัวตนของผู้ส่งข้อความ
                <br /> ท่านสามารถเว้นว่างไม่ต้องกรอกข้อมูลในช่อง "ชื่อของคุณ"
                และ "อีเมล" ได้
              </p>
              <Form className="contact-form">
                <Row>
                  <Col md="6">
                    <label>Name</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="ชื่อของคุณ"
                        type="text"
                        value={sname}
                        onChange={(event) => {
                          setSname(event.target.value);
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <label>Email</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="อีเมล"
                        type="text"
                        value={semail}
                        onChange={(event) => {
                          setSemail(event.target.value);
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <label>Message</label>
                <Input
                  placeholder="ข้อความ ..."
                  type="textarea"
                  rows="4"
                  value={smessage}
                  onChange={(event) => {
                    setSmessage(event.target.value);
                  }}
                />
                <Row>
                  <Col className="ml-auto mr-auto" md="4">
                    <Button
                      className="btn-fill"
                      color="danger"
                      size="lg"
                      onClick={() => sndEmail(sname,semail,smessage)}
                    >
                      ส่งข้อความ
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Modal */}
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="modal-dialog modal-lg"
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className="modal-title text-left" id="exampleModalLabel">
            สายตรงผู้อำนวยการกองแผนงาน
          </h5>
        </div>
        <div className="modal-body text-center">
                  {/* {sndStatus} */}
        </div>
        <div className="modal-footer">
          <div>
            <Button
              className="btn-link"
              color="info"
              type="button"
              onClick={toggleModal}
            >
              <h4>
                <i className="nc-icon nc-simple-remove" />
                <span className="ml-3">ปิด</span>
              </h4>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SectionLogin;
