import React, { useState, useEffect } from "react";
import QRCode from "../qrcode/QRCode";
import validator from 'validator';
import { uniqueID } from "dev-unique-id";
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import Switch from "react-switch";
import "./style.css";
import axios from "axios";
import configData from "../../config.json";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function App() {

  const [url, setUrl] = useState("Plan Division of Silpakorn University");
  const [shortUrl, setShort] = useState("");
  // const [id, setID] = useState("");
  const optionsLOGO = {
    ecLevel: "M", //The error correction level of the QR Code
    enableCORS: false, //Enable crossorigin attribute
    size: 200, //The size of the QR Code
    quietZone: 10, //The size of the quiet zone around the QR Code. This will have the same color as QR Code bgColor
    bgColor: "#FFFFFF", //Background color
    fgColor: "#000000", //Foreground color
    logoImage: "https://www.inter.su.ac.th/wp-content/uploads/2020/02/logo_eng.png", //The logo image. It can be a url/path or a base64 value
    logoWidth: 150,
    logoHeight: 150,
    logoOpacity: 0.5,
    qrStyle: "squares", //Style of the QR Code modules - dots or squares
  };

  const options = {
    ecLevel: "M", //The error correction level of the QR Code
    enableCORS: false, //Enable crossorigin attribute
    size: 200, //The size of the QR Code
    quietZone: 10, //The size of the quiet zone around the QR Code. This will have the same color as QR Code bgColor
    bgColor: "#FFFFFF", //Background color
    fgColor: "#000000", //Foreground color
    logoWidth: 150,
    logoHeight: 150,
    logoOpacity: 0.5,
    qrStyle: "squares", //Style of the QR Code modules - dots or squares
  };

  const [current] = useState(new Date());

  function generateQRCode() {

    let website = document.getElementById("website").value;
    if (validator.isURL(website)) {
      // console.log(website);
      setUrl(website);
      var shortID = uniqueID(5);
      // setID(shortID);
      setShort(`https://www.plan.su.ac.th/r/?r=${shortID}`)

      //Save Data ลง Table
      axios
        .get("https://www.plan.su.ac.th/r/saveqr.php", {
          params: {
            id: shortID,
            target: website,
            log: `${current} ${ip}`,
            token: configData.TOKEN
          }
        })
        .then((Response) => setResult(true))
        .catch((err) => setResult(false));

    } else {
      alert("กรุณาใส่ url ให้ถูกต้อง");
    }

  }


  const [sSwitch, setSswitch] = useState(true);
  const handleChange = () => {
    setSswitch(!sSwitch);
    settxtToclipboard(false);
  }

  const [lSwitch, setLswitch] = useState(true);
  const lhandleChange = () => {
    setLswitch(!lSwitch);
    settxtToclipboard(false);
  }

  const [result, setResult] = useState(true);

  const [txtToclipboard, settxtToclipboard] = useState(false);

  const [ip, setIP] = useState("");

  useEffect(() => {
    const getIP = async () => {
      const res = await axios.get("https://api.ipify.org/?format=json");
      setIP(res.data.ip);
    };
    getIP();
  }, []);

  return (
    <>
      <Row>
        <Col md="10" className="mt-0">
          <h2 className="text-center">
            บริการสร้าง QR code จากที่อยู่เว็บไซต์
          </h2>
          <h5 className="text-center">
            หมายเหตุ : QR code ที่สร้างจากเว็บไซต์นี้ใช้ได้ตลอดไป (ไม่มีกำหนดหมดอายุ)
          </h5>
          <InputGroup className="mt-5">
            <Input
              placeholder="ป้อน url เช่น http://plan.su.ac.th"
              type="url"
              id="website"
              name="website"
            />
            <InputGroupAddon>
              <Button color="secondary" onClick={generateQRCode}>
                สร้าง QR Code
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>

        <Col md="2" >

          {result ? (

            <div>
              <QRCode url={sSwitch ? shortUrl : url} options={lSwitch ? optionsLOGO : options} />
            </div>
          ) : <span className="text-danger">Error กรุณาลองใหม่อีกครั้ง</span>}

        </Col>
      </Row>
      <Row className="align-items-center align-middle">
        <Col md="2" >
          <Switch onChange={lhandleChange} checked={lSwitch} className="react-switch" /><span className="ml-3">{lSwitch ? "logo" : "no Logo"}</span>
        </Col>
        <Col md="2">
          <Switch onChange={handleChange} checked={sSwitch} className="react-switch" /><span className="ml-3">{sSwitch ? "short URL" : "Full URL"}</span>
        </Col>
        <Col>
          {sSwitch ? (
            <>
              <CopyToClipboard
                text={shortUrl}
                onCopy={() => settxtToclipboard(true)}
                className="mt-0"
              >
                <span>{shortUrl}</span>
              </CopyToClipboard>
              {txtToclipboard ? (
                <span style={{ color: "yellow" }}> || คัดลอก url ไปที่ clipboard แล้ว</span>
              ) : null}
            </>
          ) : null}
        </Col>
      </Row>
      <Row></Row>
    </>
  );
}
