/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
// import DialogSelect from "components/Datasort/DataSortSelect";
import PlanDataTreeView from "components/Datasort/TreeView3";

function DataCenter() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/faces/joe-gardner-2-edit.jpg").default}
              />
            </div>
            <div className="name">
              <h4 className="title">
                ศูนย์ข้อมูลกองแผนงาน <br />
              </h4>
            </div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p>
                ศูนย์ข้อมูลกองแผนงาน คือ แหล่งรวบรวมข้อมูล เอกสาร รายงาน ฯลฯ
                เพื่ออำนวยความสะดวกในการจัดเก็บและสืบค้น
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <PlanDataTreeView />
            </Col>
          </Row>
          <Row className="text-center mt-5">
            <Col>
              <Link to="/">
                <Button outline color="danger" size="sm">
                  กลับหน้าหลัก
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default DataCenter;
