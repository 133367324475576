import React, { useEffect, useContext } from "react";
import { gapi } from "gapi-script";
import LoginButton from "components/LoginLogout/login";
// import LogoutButton from "components/LoginLogout/logout";
import { Redirect } from "react-router-dom";
import gLogin from "GoogleLogin.json";
import { AuthContext } from "auth/auth.js";

import { Card, Container, Row, Col } from "react-bootstrap";
import NotificationAlert from "react-notification-alert";

function GoogleLogin() {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: gLogin.web.client_id,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });
  const { currentUser } = useContext(AuthContext);
  // const setRedirect = (st) => {
  //   setLoginStatus(st);
  // };
  // const [loginStatus, setLoginStatus] = useState(false);
  const notificationAlertRef = React.useRef(null);
  const notify = (place, color, mss) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{mss}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  if(currentUser != null) if (currentUser.loginstatus === 1) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Card className="mt-5">
          <Card.Header>
            <Card.Title as="h4">Login เข้าสู่ระบบ BackOffice เว็บไซต์กองแผนงาน</Card.Title>
            <p className="card-category">กองแผนงานมหาวิทยาลัยศิลปากร</p>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col className="text-center" md="12">
                <h4 className="title">Login ด้วยอีเมล @silpakorn.edu </h4>
              </Col>
            </Row>
            <Row className="mt-3 mb-4">
              <Col className="text-center" md="5"></Col>
              <Col className="text-center" md="2">
                <LoginButton alertFn={notify} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default GoogleLogin;
