/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState,useEffect} from "react";
import axios from "axios";
import plan_logo from "../../assets/img/PlanLogo.png"
import configData from "../../config.json";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Card,
  UncontrolledTooltip,
  // CarouselCaption,
  Button} from "reactstrap";

  // function stripHtml(html) {
  //   // Create a new div element
  //   var temporalDivElement = document.createElement("div");
  //   // Set the HTML content with the providen
  //   temporalDivElement.innerHTML = html;
  //   // Retrieve the text property of the element (cross-browser support)
  //   return temporalDivElement.textContent || temporalDivElement.innerText || "";
  // }

function LandingPageHeader() {
  useEffect(() => {
    getData("ct_date", "DESC");
  }, []);

  const [item,setItems] =  useState([]);

  const getData = () => {
    axios
      .get(configData.API_URL + "/w3_api/api/post/showheadbn.php", {
        params: {
          field: "content_id",
          desc: "desc",
          type: "19",
        },
      })
      .then((Response) => setItems(Response.data))
  };

  var items = [];
  if(item.length){
    items = item;
  }
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const linkTo = (l) => {
    window.open(l, "_blank");
  }
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }); 

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/IMG_20210706_175710-ad.png").default +
            ")",
        }}
        className="page-header page-header-md"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container className="mt-5 pt-5">
          <Row>
            <Col className="ml-auto mr-auto mt-5" lg={2} md={4} xs={5}>
              <img src={plan_logo} width="170" alt="" />
            </Col>
            <Col lg={6} md={8} xs={10} className="ml-0 mr-auto mt-5">
              <div className="motto">
                <h1 className="text-primary">กองแผนงาน</h1>
                <h3>ยึดหลักวิชาการ ประสานความคิด ชี้ทิศการพัฒนา</h3>
                <br />
                <Button
                  href="https://youtu.be/i8RO6dBQYN0"
                  className="btn-round mr-1"
                  color="neutral"
                  target="_blank"
                  id="tooltip984013562"
                  outline
                >
                  <i className="fa fa-youtube-play" />
                  VDO Clip แนะนำการค้นหาข้อมูลในเว็บไซต์กองแผนงาน
                  มหาวิทยาลัยศิลปากร
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  placement="bottom"
                  target="tooltip984013562"
                >
                  แนะนำการค้นหาข้อมูล/เอกสารบนเว็บไซต์กองแผนงาน
                </UncontrolledTooltip>
                {/* <Button
                  className="btn-round"
                  color="neutral"
                  type="button"
                  outline
                >
                  ช่องทางการติดต่อกองแผนงาน
                </Button> */}
              </div>
            </Col>
            <Col lg={4} md={8} xs={10}>
              <Card className="page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  ride="carousel"
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.ct_thumbnail}
                        href={item.ct_file}
                      >
                        <img
                          src={item.ct_thumbnail}
                          alt={item.ct_caption}
                          onClick={(e) => {
                            e.preventDefault();
                            linkTo(item.ct_file);
                          }}
                        />

                        {/* <CarouselCaption
                          captionText={stripHtml(item.ct_detail)}
                          captionHeader={stripHtml(item.ct_caption)}
                        /> */}
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* <div
          className="moving-clouds"
          style={{
            backgroundImage:
              "url(" + require("assets/img/clouds.png").default + ")",
          }}
        /> */}
      </div>
    </>
  ); 
}

export default LandingPageHeader;
