/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/OrgChartHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import { Tree, TreeNode } from "react-organizational-chart";
import OrgCard from "components/Card/OrgCard";

function OrgChart() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  return (
    <div>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/faces/joe-gardner-2-edit.jpg").default}
              />
            </div>
            <div className="name">
              <h4 className="title">กองแผนงาน มหาวิทยาลัยศิลปากร</h4>
            </div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="mt-3">
                กองแผนงาน เป็นหน่วยงานระดับกอง สังกัดสํานักงานอธิการบดี
                มหาวิทยาลัยศิลปากร <br />
                ปฏิบัติงานในฐานะหน่วยงานสนับสนุนของมหาวิทยาลัย
                มีบทบาทในการการสนับสนุนภารกิจหลักของ มหาวิทยาลัยศิลปากร
                ให้ก้าวไปสู่การเป็น <br /> มหาวิทยาลัยชั้นนําแห่งการสร้างสรรค์
                (Leading Creative University)
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center mt-5" md="12">
              <h4 className="mb-4">ประวัติความเป็นมา</h4>
              <p className="text-left">
                กองแผนงาน เป็นหน่วยงานในระดับกอง สังกัดสำนักงานอธิการบดี
                ปฏิบัติงานในฐานะหน่วยงานสนับสนุนของมหาวิทยาลัยศิลปากร
                โดยมีบทบาทสำคัญในการสนับสนุนการดำเนินพันธกิจต่าง ๆ
                ของมหาวิทยาลัยศิลปากร ไปสู่การเป็นสถาบันอุดมศึกษาที่มีคุณภาพ
                และมาตรฐานชั้นนำของประเทศ
                เดิมกองแผนงานมีฐานะเป็นหน่วยวิจัยสถาบัน และทบวงมหาวิทยาลัย
                (ปัจจุบันเป็นสำนักงานคณะกรรมการการอุดมศึกษา)
                อนุมัติให้แบ่งส่วนราชการในมหาวิทยาลัยศิลปากร เมื่อวันที่ 31
                มีนาคม 2536 ได้มีประกาศให้หน่วยวิจัยสถาบันเป็นหน่วยงานระดับกอง
                สังกัดสำนักงานอธิการบดี
                ปฏิบัติงานในฐานะหน่วยงานสนับสนุนการดำเนินงานของมหาวิทยาลัย
                ในด้านต่าง ๆ โดยมีภารกิจ ดังนี้
              </p>
              <Row>
                <Col md="1"></Col>
                <Col md="7">
                  <p className="text-left mt-3">
                    1. รวบรวมและวิเคราะห์ข้อมูล
                    เพื่อการวางแผนการพัฒนามหาวิทยาลัย
                  </p>
                  <p className="text-left">
                    2. จัดทำแผนแม่บท แผนพัฒนาการศึกษา แผนงบประมาณการศึกษา
                    และติดตามประเมินผล
                  </p>
                  <p className="text-left">
                    3. จัดทำผังแม่บท
                    เพื่อใช้เป็นแผนประกอบการพัฒนาทางกายภาพของมหาวิทยาลัย
                  </p>
                  <p className="text-left">
                    4. จัดทำหนังสือ เอกสารเผยแพร่ทั้งภายในและภายนอกมหาวิทยาลัย{" "}
                  </p>
                  <p className="text-left">
                    5. ประสานความร่วมมือด้านต่าง ๆ ทั้งภายในและภายนอกมหาวิทยาลัย
                  </p>
                </Col>
                <Col md="3"></Col>
              </Row>
            </Col>
            <Col className="ml-auto mr-auto text-center mt-5" md="12">
              <h4 className="mb-4">โครงสร้างการบริหารงาน</h4>
              <Tree
                label="มหาวิทยาลัยศิลปากร"
                lineBorderRadius="10px"
                lineColor="green"
                lineHeight="30px"
                lineWidth="3px"
                nodePadding="5px"
              >
                <TreeNode
                  label={
                    <OrgCard
                      title="มหาวิทยาลัยศิลปากร"
                      detail="อธิการบดีมหาวิทยาลัยศิลปากร"
                      image=""
                    />
                  }
                >
                  <TreeNode
                    label={
                      <OrgCard
                        title="มหาวิทยาลัยศิลปากร"
                        detail="รองอธิการบดีฝ่ายวางแผนและพัฒนา"
                        image=""
                      />
                    }
                  >
                    <TreeNode
                      label={
                        <OrgCard
                          title="มหาวิทยาลัยศิลปากร"
                          detail="ผู้ช่วยอธิการบดีฝ่ายวางแผนและจัดการนโยบาย : ผู้ช่วยอธิการบดีฝ่ายวางแผนและจัดการกายภาพ"
                          image=""
                        />
                      }
                    >
                      {/* <TreeNode
                        label={
                          <OrgCard
                            title="มหาวิทยาลัยศิลปากร"
                            detail="ผู้ช่วยอธิการบดีฝ่ายวางแผนและจัดการกายภาพ"
                            image=""
                          />
                        }
                      > */}
                        <TreeNode
                          label={
                            <OrgCard
                              title="กองแผนงาน"
                              detail="ผู้อำนวยการกองแผนงาน"
                              image=""
                            />
                          }
                        >
                          <TreeNode
                            label={
                              <OrgCard
                                title="กองแผนงาน"
                                detail="หัวหน้างานวิเคราะห์งบประมาณ"
                                image=""
                              />
                            }
                          >
                            <TreeNode
                              label={
                                <OrgCard
                                  title="งานวิเคราะห์งบประมาณ"
                                  detail="งานวิเคราะห์งบประมาณ"
                                  image=""
                                />
                              }
                            />
                          </TreeNode>

                          <TreeNode
                            label={
                              <OrgCard
                                title="กองแผนงาน"
                                detail="หัวหน้างานยุทธศาสตร์และวิจัยสถาบัน"
                                image=""
                              />
                            }
                          >
                            <TreeNode
                              label={
                                <OrgCard
                                  title="งานยุทธศาสตร์และวิจัยสถาบัน"
                                  detail="งานยุทธศาสตร์และวิจัยสถาบัน"
                                  image=""
                                />
                              }
                            />
                          </TreeNode>

                          <TreeNode
                            label={
                              <OrgCard
                                title="กองแผนงาน"
                                detail="หัวหน้างานวางแผนและพัฒนากายภาพ"
                                image=""
                              />
                            }
                          >
                            <TreeNode
                              label={
                                <OrgCard
                                  title="งานวางแผนและพัฒนากายภาพ"
                                  detail="งานวางแผนและพัฒนากายภาพ"
                                  image=""
                                />
                              }
                            />
                          </TreeNode>
                        </TreeNode>
                        {/* </TreeNode>
                    </TreeNode> */}
                      </TreeNode>
                    {/* </TreeNode> */}
                  </TreeNode>
                </TreeNode>
              </Tree>
            </Col>
          </Row>
          <br />
        </Container>
      </div>

      <DemoFooter />
    </div>
  );
}



export default OrgChart;
