/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionAbout() {
  return (
    <>
      <div className="section section-gray section-nucleo-icons">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">กองแผนงาน</h2>
              <br />
              <p className="description">
              กองแผนงาน เป็นหน่วยงานในระดับกอง สังกัดสำนักงานอธิการบดี ปฏิบัติงานในฐานะหน่วยงานสนับสนุนของมหาวิทยาลัยศิลปากร  
              โดยมีบทบาทสำคัญในการสนับสนุนการดำเนิน พันธกิจต่าง ๆ ของมหาวิทยาลัยศิลปากร  ไปสู่การเป็นสถาบันอุดมศึกษาที่มีคุณภาพ และมาตรฐานชั้นนำของประเทศ
              </p>
              <br />
              {/* <Button
                className="btn-round ml-1"
                color="danger"
                href="https://nucleoapp.com/?ref=1712"
                outline
                target="_blank"
              >
                ดูเบอร์โทรศัพท์ผู้บริหารและบุคลากร
              </Button> */}
            </Col>
            <Col lg="6" md="12">
              <div className="icons-container">
                <i className="nc-icon nc-circle-10" />
                <i className="nc-icon nc-map-big" />
                <i className="nc-icon nc-camera-compact" />
                <i className="nc-icon nc-watch-time" />
                <i className="nc-icon nc-key-25" />
                <i className="nc-icon nc-tap-01" />
                <i className="nc-icon nc-refresh-69" />
                <i className="nc-icon nc-layout-11" />
                <i className="nc-icon nc-badge" />
                <i className="nc-icon nc-bulb-63" />
                <i className="nc-icon nc-favourite-28" />
                <i className="nc-icon nc-paper" />
                <i className="nc-icon nc-laptop" />
                <i className="nc-icon nc-zoom-split" />
                <i className="nc-icon nc-cloud-download-93" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionAbout;
