import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
} from "reactstrap";
import Paper from "@material-ui/core/Paper";
import {
  TreeDataState,
  SortingState,
  CustomTreeData,
  PagingState,
  IntegratedPaging,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";

import DialogSelect from "components/Datasort/DataSortSelect";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableTreeColumn,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import axios from "axios";
import configData from "../../config.json";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const getChildRows = (row, rootRows) => (row ? row.items : rootRows);

function TreeTable(props) {
  useEffect(() => {
    getData("1", "2", "3", "");
  }, []);

  const toggleModal = () => {
    settxtToclipboard(false);
    setModal(!modal);
  };

        const toggleModal2 = () => {
          setModal2(!modal2);
        };

  const showModal = (caption, detail, file, thumbnail, content_id) => {
        setQrValue(configData.WEB_BASE + "?i=" + content_id);
    let remText = file.replace(/ /g, "");
    let length = remText.length;
    if (length > 0) {
      setCaption(caption);
      setDetail(detail);
      setFile(file);
      setThumbnail(thumbnail);
      toggleModal();
    }
  };

      const downloadQRCode = () => {
        // Generate download with use canvas and stream
        const canvas = document.getElementById("qr-gen-download");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${caption}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toggleModal2();
      };

  const [modal2, setModal2] = useState(false);
  const [qrValue, setQrValue] = useState("");
  const [txtToclipboard, settxtToclipboard] = useState(false);


  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState("");
  const [caption, setCaption] = useState("");
  const [file, setFile] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [searchText, setSearchText] = useState("");
  const [pageSizes] = useState([5, 10, 20, 50 , 100]);

  const getData = (f1, f2, year, keyword) => {
    if (f1 === 0 || f2 === 0 || year === "4") {
      f1 = "1";
      f2 = "2";
      year = "3";
      keyword = "";
    }
    if(keyword==="") setSearchText("");
    axios
      .get(configData.API_URL + "/w3_api/api/post/datatableread.php", {
        params: {
          f1: f1,
          f2: f2,
          year: year,
          keyword: keyword,
        },
      })
      .then((Response) => setData(Response.data));
  };
  // กำหนด Column ของข้อมูลที่ต้องการแสดงในตาราง
  const [columns] = useState([
    { name: "root_name", title: "รายการ" },
    // { name: 'd_download', title: 'DL' },
    { name: "d_docyear", title: "ปีเอกสาร" },
    { name: "d_date", title: "วันที่บันทึก" },
  ]);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      // eslint-disable-next-line no-alert
      onClick={() =>
        showModal(
          row["root_name"],
          row["d_detail"],
          row["d_download"],
          row["thumbnail"],
          row["root_code"]
        )
      }
      style={{
        cursor: "pointer",
      }}
    />
  );
  const [tableColumnExtensions] = useState([
    { columnName: "root_name", width: 700 },
  ]);

  return (
    <Paper>
      <Container>
        <Row className="mt-4">
          <Col className="ml-auto mr-auto text-center" md="5">
            <DialogSelect getData={getData} />
          </Col>
          <Col className="ml-auto mr-auto text-right" md="5">
            <FormGroup>
              <Input
                placeholder="คำค้นหา"
                type="text"
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col className="ml-auto mr-auto text-left" md="2">
            <Button
              className="btn-round"
              color="default"
              onClick={() => getData("1", "2", "3", searchText)}
              outline
            >
              <i className="fa fa-search" /> ค้นหา
            </Button>
          </Col>
          <Col className="ml-auto mr-auto text-center" md="2"></Col>
        </Row>
      </Container>
      <Grid rows={data} columns={columns}>
        <TreeDataState />
        <SortingState />
        <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[3]} />
        <CustomTreeData getChildRows={getChildRows} />
        <IntegratedPaging />
        <IntegratedSorting />
        <Table
          rowComponent={TableRow}
          columnExtensions={tableColumnExtensions}
        />
        <TableHeaderRow showSortingControls />
        <TableTreeColumn for="root_name" />
        <PagingPanel pageSizes={pageSizes} />
      </Grid>

      {/* Modal */}

      <Modal
        isOpen={modal2}
        toggle={toggleModal2}
        className="modal-dialog modal-md"
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={toggleModal2}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className="modal-title text-left" id="exampleModalLabel">
            คลิกที่ QRCode เพื่อดาวน์โหลด
          </h5>
        </div>
        <div className="modal-body text-center">
          <QRCode
            id="qr-gen-download"
            value={qrValue}
            size={250}
            level={"H"}
            includeMargin={true}
            className="mr-3 mt-2"
            onClick={downloadQRCode}
          />
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="modal-dialog modal-lg"
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className="modal-title text-left" id="exampleModalLabel">
            {caption}
          </h5>
        </div>
        <div className="modal-body text-center">
          <img alt="" className="img-rounded img-responsive" src={thumbnail} />
          <div
            className="mt-4 text-left"
            dangerouslySetInnerHTML={{ __html: detail }}
          ></div>
        </div>
        <div className="modal-footer">
          <div className="modal-body text-left">
            <div>
              <QRCode
                className="mr-2"
                id="qr-gen"
                value={qrValue}
                size={59}
                level={"H"}
                includeMargin={true}
                onClick={toggleModal2}
              />
            </div>
            <div>
              <CopyToClipboard
                text={qrValue}
                onCopy={() => settxtToclipboard(true)}
                className="mt-0"
              >
                <span>{qrValue}</span>
              </CopyToClipboard>
              {txtToclipboard ? (
                <span style={{ color: "red" }}> Copied.</span>
              ) : null}
            </div>
          </div>
          <div>
            <Button
              className="btn-link"
              color="info"
              type="button"
              href={file}
              target="_blank"
            >
              <h4>
                <i className="nc-icon nc-cloud-download-93" />
                <span className="ml-3">เปิดเอกสาร</span>
              </h4>
            </Button>
          </div>
        </div>
      </Modal>
    </Paper>
  );
}

export default TreeTable;
