/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch} from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import LandingPage from "views/examples/LandingPage.js";
import DataCenter from "views/examples/DataCenter.js";
import DataCenter2 from "views/examples/DataCenter2";
import DataCenter3 from "views/examples/DataCenter3";

import NewsList from "views/examples/newsList.js";
import OrgChart from "views/examples/OrgChart";
import StaffDir from "views/examples/StaffDirectory";
import Login from "views/examples/Login";
import { AuthProvider } from "auth/auth";
import configData from "./config.json";
// others

ReactDOM.render(
    <AuthProvider>
  <BrowserRouter basename={configData.BASE_NAME}>
    <Switch>
      <Route path="/" exact render={(props) => <LandingPage {...props} />} />
      <Route path="/data-center" render={(props) => <DataCenter {...props} />}/>
      <Route path="/data-center2" render={(props) => <DataCenter2 {...props} />}/>
      <Route path="/data-center3" render={(props) => <DataCenter3 {...props} />}/>
      <Route path="/news-list" render={(props) => <NewsList {...props} />} />
      <Route path="/about" render={(props) => <OrgChart {...props} />} />
      <Route path="/staff" render={(props) => <StaffDir {...props} />} />
      <Route path="/login" render={(props) => <Login {...props} />} />
    </Switch>
  </BrowserRouter>
  </AuthProvider>,
  document.getElementById("root")
);
