/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionContract() {
  return (
    <>
      <Container>
        <Row>
          <Col lg="9" sm="7" xs="7">
            <div className="owner">
              <div className="avatar mt-0">
                {/* <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/PlanLogoBK.png").default}
                /> */}
              </div>
              <div className="name  text-left">
                <h4 className="title">
                  ช่องทางการติดต่อ
                  <span className="text-info">กองแผนงาน</span>
                  <br />
                </h4>
                <h5 className="description">ที่อยู่</h5>
              </div>
            </div>
            <Row>
              <Col className="ml-auto mr-auto  text-left" md="12">
                <p>
                  กองแผนงาน ชั้น 7 อาคารสำนักงานอธิการบดีมหาวิทยาลัยศิลปากร
                  <br />
                  เลขที่ 22 ถนนบรมราชชนนี ตลิ่งชัน กรุงเทพฯ 10170
                </p>
                <br />
              </Col>
            </Row>

            <div className="name">
              <h5 className="description text-left">เบอร์โทรศัพท์/โทรสาร</h5>
            </div>

            <Row>
              <Col className="ml-auto mr-auto text-left" md="12">
                <p>โทร 0-2105-4686 โทรสาร 0-2849-7555</p>
                <br />
              </Col>
            </Row>

            <Row>
              <Col className="ml-auto mr-auto text-left" md="12">
                <p>
                  <div className="name">
                    <h5 className="description text-left">อีเมล</h5>
                  </div>

                  <Row>
                    <Col className="ml-auto mr-auto text-left" md="12">
                      <p>plan.silpakorn@gmail.com</p>
                      <br />
                    </Col>
                  </Row>
                </p>
                <br />
              </Col>
            </Row>
          </Col>
          <Col className="mt-5">
            <img
              alt="..."
              className="img-rounded img-no-padding img-responsive"
              src={require("assets/img/IMG_20210706_175755.png").default}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SectionContract;
