import React, {useContext} from "react";
import GoogleLogin from "react-google-login";
import gLogin from "GoogleLogin.json";
import { AuthContext } from "auth/auth.js";
import axios from "axios";
import configData from "config.json";
function Login(props) {
  const loginApi = configData.API_URL + "/w3_api/api/login/login.php";
  const token_config = configData.TOKEN;
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    console.log(currentUser);

  const loginCheck = (resp_data) => {
    console.log(resp_data);

      axios
        .get(loginApi, {
          params: {
            user_email: resp_data.email,
            token: token_config,
          },
        })
        .then((Response) => {
          // console.log(Response.data)
          if (Response.data.result===1) {
            setCurrentUser({
              google_data : resp_data,
              loginstatus : Response.data.result,
            });
          } else {
             props.alertFn("tc", 3, "โปรแกรมส่วนนี้สำหรับผู้ดูแลระบบเท่านั้น");
          }
        })
        .catch((err) => {
          props.alertFn("tc", 3, "Failure " + err);
          // console.log(err);
        });

    // console.log(update);
  };

  const onSuccess = (Response) => {
    // props.reDir(true);
    loginCheck(Response.profileObj);
  };
  const onFailure = (Response) => {
    props.alertFn("tc", 3, "เข้าสู่ระบบไม่สำเร็จกรุณาตรวจสอบ");
    console.log("login Failure! : ", Response);
  };
  return (
    <div id="signInButton">
      <GoogleLogin
        clientId={gLogin.web.client_id}
        buttonText="เข้าสู่ระบบ"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
      />
    </div>
  );
}

export default Login;
